import { m } from "framer-motion";
import styled from "styled-components";

export const Container = styled(m.div)`
  background-color: rgba(118, 183, 42, 0.8);
  width: 100%;
  max-width: 536px;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  > span {
    display: inline-block;
    padding: 0 5px;
  }
`;
