import React from "react";
import { Container } from "./style";

const ContactBox = () => {
  return (
    <Container key='ContactBox'>
      <p>Zapraszamy do kontaktu:</p>
      <div>
        <a href='mailto:kontakt@ineron.pl'>kontakt@ineron.pl</a>
        <span className='green-text'> lub </span>
        <a href='tel:+48790244444'>+48 790 244 444</a>
      </div>
    </Container>
  );
};

export default ContactBox;
