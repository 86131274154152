import React from "react";
import { Container } from "./style";

const DescriptionBox = () => {
  return (
    <Container>
      <div>
        <p>Fotowoltaika - dla biznesu / przemysłu / samorządów</p>
      </div>
      <div>
        <p>Farmy fotowoltaiczne - projektowanie / wykonawstwo / zarządzanie</p>
      </div>
      <div>
        <p>Energia zielona i gaz dla biznesu, cPPA, odkup energii z oze</p>
      </div>
    </Container>
  );
};

export default DescriptionBox;
