import { m } from "framer-motion";
import styled from "styled-components";

export const Container = styled(m.div)`
  width: 100%;
  padding: 10px 0;
  text-align: center;
  color: #fff;
  font-weight: 600;

  p {
    display: inline-block;
    padding: 10px;
    text-shadow: 0px 0px 20px #000;
  }

  @media (min-width: 1000px) and (min-height: 800px) {
    display: none;
  }
`;
