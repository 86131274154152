import { m } from "framer-motion";
import styled from "styled-components";

export const Container = styled(m.div)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  min-height: 150px;
  padding: 20px;
  flex: 1 0 100%;
  position: absolute;
  top: 50vh;
  transform: translateY(-50%);
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ImgContainer = styled(m.div)`
  max-height: 150px;
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px 0;
  @media (max-height: 500px) {
    max-width: 220px;
  }
`;
export const ButtonContainer = styled(m.div)`
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
`;
