import { m } from "framer-motion";
import styled from "styled-components";

export const Container = styled(m.div)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  height: 100%;
`;

export const Inner = styled(m.div)`
  width: 100%;
  height: 50vh;
  padding: 10px 0;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    display: inline-block;
    padding: 10px;
    font-size: 18px;
    text-shadow: 0px 0px 20px #000;
  }

  @media (max-width: 1000px), (max-height: 800px) {
    visibility: hidden;
    opacity: 0;
  }
`;

export const Top = styled(m.div)`
  display: flex;
  padding: 10px;
  > div {
    flex: 1 1;
    text-align: center;
  }
`;
export const Bottom = styled(m.div)`
  display: flex;
  padding: 10px;
  > div {
    flex: 1 1;
    text-align: center;
  }
`;
