import React from "react";
import { Container } from "./style";

const InfoBox = () => {
  return (
    <Container key='InfoBox'>
      <span>Strona w przebudowie.</span>
      <span>Zmieniamy się dla Ciebie</span>
    </Container>
  );
};

export default InfoBox;
