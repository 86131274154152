import React from "react";
import Button from "../button";
// import IneronLogo from "../../images/Ineron.svg";
// import ReLogo from "../../images/RE.svg";

import { StaticImage } from "gatsby-plugin-image";
import { Container, ImgContainer, ButtonContainer } from "./style";

const MiddleSection = ({ setIsShow, isShow }) => {
  return (
    <>
      <Container>
        <ImgContainer key='Ineron' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3, duration: 0.4 }}>
          <StaticImage src='../../images/Ineron.png' alt='Ineron' />
        </ImgContainer>
        <ButtonContainer id='button'>
          <Button isShow={isShow} setIsShow={setIsShow} />
        </ButtonContainer>
        <ImgContainer key='Re' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3, duration: 0.4 }}>
          <StaticImage src='../../images/RE.png' alt='RE' />
        </ImgContainer>
      </Container>
    </>
  );
};

export default MiddleSection;
