import React from "react";
import InfoBox from "../infoBox";
import ContactBox from "../contactBox";
import DescriptionBox from "../descriptionBox";
import { Container, Inner } from "./style";

const BottomSection = () => {
  return (
    <Container key='BottomSection'>
      <Inner>
        <DescriptionBox />
        <ContactBox />
        <InfoBox />
      </Inner>
    </Container>
  );
};

export default BottomSection;
