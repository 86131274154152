import { CircleOutside, CircleInside } from "./style";
import React from "react";
import { m } from "framer-motion";

const Button = ({ setIsShow, isShow }) => {
  const variants = {
    clicked: { scale: 1.2, backgroundColor: "#84D426", transition: { ease: "easeOut", duration: 0.3 } },
    normal: { scale: 1, backgroundColor: "rgba(118, 183, 42, 1)", transition: { ease: "easeOut", duration: 0.3 } },
  };

  return (
    <CircleOutside onClick={() => setIsShow(!isShow)} key='CircleOutside'>
      <CircleInside layout key='CircleInside' variants={variants} whileHover='clicked' animate={isShow ? "clicked" : "normal"}>
        <svg xmlns='http://www.w3.org/2000/svg' width={35} height={35} fill='none' viewBox='0 0 24 24' stroke='#fff' strokeWidth={3}>
          <m.path strokeLinecap='round' strokeLinejoin='round' d='M12 4v16m8-8H4' />
        </svg>
      </CircleInside>
    </CircleOutside>
  );
};

export default Button;
