import { m } from "framer-motion";
import styled from "styled-components";

export const CircleOutside = styled(m.div)`
  width: 9.375em;
  height: 9.375em;
  min-width: 9.375em;
  min-height: 9.375em;
  background-color: rgba(118, 183, 42, 0.35);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleInside = styled(m.div)`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
