import { m } from "framer-motion";
import React from "react";

import { Container, Inner, Top, Bottom } from "./style";

const TopSection = ({ isShow }) => {
  return (
    <Container>
      <Inner>
        <Top>
          <div></div>
          <m.div initial={{ opacity: 0 }} animate={isShow ? { opacity: 1 } : { opacity: 0 }} exit={{ opacity: 0 }} id='middleText'>
            <p>Fotowoltaika - dla biznesu / przemysłu / samorządów</p>
          </m.div>
          <div></div>
        </Top>
        <Bottom>
          <m.div initial={{ opacity: 0 }} animate={isShow ? { opacity: 1 } : { opacity: 0 }} exit={{ opacity: 0 }} id='leftText'>
            <p>Farmy fotowoltaiczne - projektowanie / wykonawstwo / zarządzanie</p>
          </m.div>
          <div></div>
          <m.div initial={{ opacity: 0 }} animate={isShow ? { opacity: 1 } : { opacity: 0 }} exit={{ opacity: 0 }} id='rightText'>
            <p>Energia zielona i gaz dla biznesu, cPPA, odkup energii z oze</p>
          </m.div>
        </Bottom>
      </Inner>
    </Container>
  );
};

export default TopSection;
