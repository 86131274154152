import React, { useState } from "react";

import TopSection from "../components/TopSection";
import BottomSection from "../components/BottomSection";
import MiddleSection from "../components/MiddleSection";
import ArrowSection from "../components/ArrowSection";

import { Helmet } from "react-helmet";
import { Xwrapper } from "react-xarrows";

const IndexPage = () => {
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "pl",
        }}>
        <meta charSet='utf-8' />
        <meta name='icon' href='../images/icon.png' />
        <meta name='description' content='Ineron - strona w budowie'></meta>

        <title>Ineron</title>
        <link rel='canonical' href='https://dapper-creponne-3ba799.netlify.app/' />
      </Helmet>
      <Xwrapper>
        <TopSection isShow={isShow} />
        <ArrowSection isShow={isShow} />
        <MiddleSection isShow={isShow} setIsShow={setIsShow} />
        <BottomSection />
      </Xwrapper>
    </>
  );
};

export default IndexPage;
