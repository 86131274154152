import React from "react";
import Xarrow from "react-xarrows";
import { Container } from "./style";

const ArrowSection = ({ isShow }) => {
  return (
    <Container initial={{ opacity: 0 }} animate={isShow ? { opacity: 1 } : { opacity: 0 }} exit={{ opacity: 0 }}>
      <Xarrow
        curveness={0}
        showHead={false}
        color='#fff'
        strokeWidth={2}
        startAnchor={("bottom", { position: "bottom", offset: { y: +10 } })}
        endAnchor={("top", { position: "top", offset: { y: -50 } })}
        start={"middleText"}
        end={"button"}
      />
      <Xarrow curveness={0} showHead={false} color='#fff' strokeWidth={2} endAnchor={("left", { position: "left", offset: { y: -100 } })} start={"leftText"} end={"button"} />
      <Xarrow curveness={0} showHead={false} color='#fff' strokeWidth={2} endAnchor={("right", { position: "right", offset: { y: -100 } })} start={"rightText"} end={"button"} />
    </Container>
  );
};

export default ArrowSection;
