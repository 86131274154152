import { m } from "framer-motion";
import styled from "styled-components";

export const Container = styled(m.div)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  min-height: 50vh;
`;

export const Inner = styled(m.div)`
  width: 100%;
  height: 100%;
  margin-top: 100px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 200px;
    justify-content: flex-end;
  }
`;
