import { m } from "framer-motion";
import styled from "styled-components";

export const Container = styled(m.div)`
  width: 100%;

  padding: 30px 0;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;

  p {
    display: inline-block;
    padding: 10px;
    font-size: 18px;
    text-shadow: 0px 0px 20px #000;
  }

  a {
    display: inline-block;
    padding: 10px;
    font-size: 24px;
    text-shadow: 0px 0px 20px #000;
  }
  span {
    display: inline-block;
    padding: 10px;
    color: rgba(118, 183, 42, 1);
    font-size: 24px;
    font-weight: 600;
    text-shadow: 0px 0px 20px #000;
  }
  @media (max-width: 572px) {
    span {
      display: none;
    }
  } ;
`;
